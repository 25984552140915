<template>
  <div>
    <div style="position: absolute; top: 20px; right: 20px">
      <el-button type="primary" size="small" @click="allRead"
        >设置为已读</el-button
      >
    </div>

    <el-table
      :data="tableData"
      style="width: 100%"
      highlight-current-row
      @current-change="tableCurrentChange"
    >
      <el-table-column prop="date" label="编号" align="center">
        <template slot-scope="scope">
          <div class="info">
            {{ scope.row.id }}
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="type_title" label="类型" align="center">
        <template slot-scope="scope">
          <div class="info">
            {{ scope.row.type_title }}
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="address" label="内容" align="center">
        <template slot-scope="scope">
          <div class="info">
            {{ scope.row.content }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="time" label="时间" align="center">
        <template slot-scope="scope">
          <div class="info">
            {{ scope.row.create_time_f }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="状态" align="center">
        <template slot-scope="scope">
          <div class="info">
            {{ scope.row.is_read == 1 ? "已读" : "未读" }}
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div :style="$attrs.pageStyle">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        @current-change="getMessageList((page = $event))"
        :page-size="pageSize"
        :current-page="page"
        hide-on-single-page
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { message } from "@/api/me";

export default {
  props: {
    type: [Number, String],
  },
  data() {
    return {
      page: 1,
      pageSize: 50,
      tableData: [],
      total: 0,
      loading: false,
    };
  },
  created(){
    console.log(this.type,"typeeeeee")
  },
  methods: {
    routeUpdate() {
      this.getMessageList();
    },
    getMessageList() {
      this.loading = true;
      message({
        type: this.type,
        page: this.page,
        page_size: this.pageSize,
      })
        .then(({ data }) => {
          this.tableData = data.data;
          this.total = data.total;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    allRead() {
      this.$confirm("是否将消息全部设置为已读?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let data = {
            ids: this.tableData.map((v) => v.id).join(","),
          };
          this.$http.post("/api/v1_0_0.message/readMore", data).then((res) => {
            this.$message({
              type: "success",
              message: "全部已读成功!",
            });
            this.$http.get("/api/v1_0_0.user/msg_not_read").then((res) => {
              this.$store.commit("SET", {
                key: "msg_notread",
                value: res.data.msg_notread,
              });
            });
            this.getMessageList();
            this.$emit('updateData')
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
      console.log("全部已读");
    },
    tableCurrentChange(e) {
      let obj = { title: "", content: e.content };
      obj.title = ["系统消息", "系统消息", "互动消息"][this.type] || "";
      this.$router.push({
        path: "/me/meMessageDetaild",
        query: { id: e.id, type: obj.title, content: e.content },
      });
    },
  },
};
</script>

<style scoped>
</style>